@use "~@angular/material" as mat;
@include mat.core();

@import "./fonts";
@import "./_variables";
@import "material-icons/iconfont/material-icons.css";

$tausi-primary: (
  main: #007dc3,
  lighter: #b3d8ed,
  darker: #0060ae,
  200: #007dc3,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

$tausi-core-primary: mat.define-palette($tausi-primary, main, lighter, darker);
$tausi-core-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$tausi-core-warn: mat.define-palette(mat.$red-palette);
$tausi-typography: mat.define-typography-config(
  $font-family:
    ' "American",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
);
$tausi-core-theme: mat.define-light-theme(
  (
    color: (
      primary: $tausi-core-primary,
      accent: $tausi-core-accent,
      warn: $tausi-core-warn,
    ),
    typography: $tausi-typography,
  )
);
@include mat.all-component-themes($tausi-core-theme);

@import url(./theme.css);

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body,
html {
  height: 100%;
}

* {
  margin: 0;
}

body {
  margin: 0;
  // line-height: 24px;
  min-height: 100%;
  font-family: "American", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.icon-lg {
  font-size: 1.2rem;
}

::-webkit-scrollbar {
  width: 6px;
  overflow-y: scroll;
  background: rgb(167, 167, 167);
}

::-webkit-scrollbar-thumb {
  background: rgb(133, 133, 133);
  border-radius: 10px;
}

.spacer-top {
  margin-top: 4rem;
}

.p-progress-spinner-circle {
  stroke: #ffffff !important;
}

.otp-input {
  width: 70px !important;
  height: 75px !important;
  border-radius: 0.4rem !important;
  text-align: center !important;
  font-size: 2rem !important;
  font-weight: 600;

  color: rgba(0, 0, 0, 0.87) !important;
  background: #ffffff !important;
  padding: 1rem !important;
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  margin-right: 1rem !important;
}

/*
  * primeng component custom but global styling
  * website
*/
.p-component {
  font-family: "American", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: $border-radius;
}

.p-dropdown {
  border-radius: $border-radius !important;
}

.p-dropdown > .p-dropdown-label {
  padding: 0.6rem 0.8rem !important;
}

.p-calendar {
  border-radius: $border-radius !important;
}

.p-inputtext {
  padding-top: 0.6rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: $border-radius !important;
  font-size: 1rem !important;
}

.p-button {
  padding: 0.6rem 1.4rem 0.7rem 1.4rem !important;
  border-radius: $border-radius;
  font-weight: 600 !important;
}

.p-button-large {
  padding: 1rem 1.4rem !important;
  border-radius: $border-radius;
  font-weight: 600 !important;
}

/*
  * primeng component custom but global styling
*/

.mat-icon-button .mat-icon {
  line-height: 23px !important;
}

// .wrapper {
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

.wrapper-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 2rem;
  }
}

/*
  * global spacer
*/
.spacer {
  flex: 1 1 auto;
}

/*
  * styling for mat-sidenav content
*/
.mat-sidenav-container {
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .mat-sidenav-content {
    display: flex;
    flex-direction: column;
  }
}

/*
  * fab styling
*/
.mat-fab {
  top: auto;
  right: 40px;
  bottom: 50px;
  left: auto;
  transform: scale(1);
  position: fixed !important;
  z-index: 20;
  line-height: 56px;
  min-width: 0;
  width: 56px;
  height: 56px;
  vertical-align: middle;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
}

.mat-fab-top {
  margin-bottom: 4rem !important;
}

/*
  * full width for input styles
*/
.mat-form-field {
  width: 100%;
}

/** this line ensures form fields occupy container size */
mat-form-field {
  width: 100% !important;
}

/** global style for breadcrumb*/

.xng-breadcrumb-root {
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;

  .xng-breadcrumb-trail {
    margin-top: 0px;
    font-weight: 500;
    color: $main-primary;
  }
}

.xng-breadcrumb-separator {
  padding: 0 4px;
  color: $main-primary;
}

/**
  all global styling for for texts
  shall be placed in this section with text as a prepend
  They must contain description is need be.
*/
.text-500 {
  font-weight: 500;
}

.text-600 {
  font-weight: 600;
}

.text-700 {
  font-weight: 700;
}

.text-800 {
  font-weight: 800;
}

.text-900 {
  font-weight: 900;
}

.tausi-content-header-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;

  h6 {
    font-weight: 600 !important;
    color: #007dc3;
  }

  p {
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 600;
    margin-top: -10px !important;
    padding: 4px 0 0 0 !important;
  }
}

.tausi-content-header-outer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 0rem;

  h4 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600 !important;
    color: #007dc3;
    margin: 0 !important;
    padding: 0 !important;
  }

  h5 {
    font-weight: 600 !important;
    color: #7a7a7a;
  }

  p {
    color: #7a7a7a;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0.5rem 0px !important;
    padding: 4px 0 0 0 !important;
  }

  .with-hr-line-fit-content {
    width: fit-content;

    hr {
      color: black;
    }
  }
}

.setup-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;

  h4 {
    font-weight: 600 !important;
    color: #007dc3;
  }

  span {
    color: #949494;
    margin-left: 1rem;
    border-left: 1px solid #afafaf;
    padding-left: 1rem;
  }
}

/**
* --------------------------------------------------------------------
* end of global text styling
* -------------------------------------------------------------------
*/

.financial-year-underline-text {
  text-decoration-line: underline;
  text-underline-offset: 10px;
  color: rgba(0, 0, 0, 0.4);
}

/**

  this section will contain all primeng and angular material
  override styling that will be applied across the application

  each override must be listed below
  @p-card
  @p-dialog
  @p-datatable
*/
.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.p-selectbutton
  .p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-left,
.p-selectbutton
  .p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-right {
  color: rgba(0, 0, 0, 0.6);
}

.p-selectbutton .p-button.p-highlight {
  background: #e0e0e1;
  border-color: #e0e0e1;
  color: rgba(0, 0, 0, 0.87);
}

.p-badge {
  font-size: 0.85rem;
  border-radius: 8rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #ffffff;
  padding: 0.55rem !important;

  .p-button {
    padding: 0.5rem 1.4rem !important;
    border-radius: 2px !important;
  }
}

.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 0.6rem 1rem;
  border: 0.6px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  .p-progressbar {
    display: none !important;
  }
}

.p-panel .p-panel-content {
  padding: 0.75rem;
  border: 1px solid transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-top: 0 none;
}

.p-panel .p-panel-header {
  border: 1px solid transparent;
  padding: 0.9rem 0.75rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-top-right-radius: $border-radius;
  border-top-left-radius: $border-radius;
}

.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
  font-size: 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  box-shadow: none !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  position: relative !important;
  transition: margin-bottom 225ms !important;
}

.p-panelmenu .p-panelmenu-panel:first-child {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.p-panelmenu .p-panelmenu-panel:last-child {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.p-panelmenu .p-panelmenu-header > a {
  padding: 1.25rem !important;
  border: 0 none !important;
  color: #ffffff !important;
  background: transparent !important;
  font-size: 1.15rem !important;
  font-weight: 600 !important;
  border-radius: 0px !important;
  transition: none !important;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0rem 0 0rem 0 !important;
  background: #d9eaf9 !important;
  color: #ffffff !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 1rem 0.75rem 1rem 3.5rem !important;
  font-size: 1.2 rem !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: transparent !important;
  border-right: 5px solid transparent !important;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem !important;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
  font-size: 1.3rem !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: rgb(0, 0, 0) !important;
  font-size: 1.5rem !important;
}

.p-menu {
  padding: 0.6rem 0;
  border-radius: $border-radius;
  width: 16rem;
}

.p-menu .p-menuitem-link {
  padding: 0.95rem 0.75rem !important;
}

.p-tabview-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.p-tag-value {
  padding: 0rem 0.4rem !important;
  font-size: 11px;
}

// toast customization
.p-toast {
  opacity: 1 !important;
}

.p-message .p-message-wrapper {
  padding: 1rem 1.25rem !important;
}

.p-toast .p-toast-message {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px !important;
  border-radius: 0px !important;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #dcefff !important;
  color: #01579b !important;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #01579b !important;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: #b4fbd3 !important;
  color: #1b5e20 !important;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #1b5e20 !important;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #fceedb !important;
  color: #7f6003 !important;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #7f6003 !important;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #ffcdd2 !important;
  color: #b71c1c !important;
}

// end of toast customization

/** tooltip section */
.p-tooltip .p-tooltip-text {
  background: rgba(39, 39, 39, 0.9);
  font-size: $font-size;
  color: #ffffff;
  padding: 0.6rem 1rem !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: $border-radius !important;
}

/** end tooltip section */

.p-card {
  border-radius: $border-radius !important;
  padding: 0px;

  .p-card-body {
    padding: 0px;
  }

  .p-card-content {
    padding: 0px;
  }
}

.p-dialog {
  border-radius: $border-radius;
  background-color: white !important;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 1.25rem 1.25rem 0.3rem 1.25rem;
  border-top-right-radius: $border-radius;
  border-top-left-radius: $border-radius;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  // font-size: 1.4rem;
  color: $main-primary;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.2rem 2rem 1.25rem 1.5rem;
  border-radius: $border-radius;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2.5rem;
  margin-left: 0.6rem;
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.75rem 1.25rem 0.85rem 1.25rem;
  text-align: right;
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.p-datatable .p-datatable-header {
  background: #cccccc !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.8rem 0rem !important;
  border-bottom: 1px solid #cecece;
  background-color: #e6e6e6 !important;
  font-size: 15px;
  font-weight: 600;
}

.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.7rem 0px !important;
  background-color: #f0f0f0 !important;
  font-size: 15px;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0rem !important;
}

.p-datatable .p-datatable-tbody > tr > td:first-child {
  text-align: center;
  font-weight: 600 !important;
}

.p-datatable-scrollable-wrapper {
  border: none !important;
}

.p-datatable .p-datatable-thead > tr > th:first-child {
  padding: 0px 1rem !important;
  width: 7%;
  text-align: center;
  font-weight: 600 !important;
}

.p-datatable .p-datatable-thead > tr > th:last-child {
  padding: 0px 1rem !important;
  // width: 10%;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 0 0;
  border-radius: $border-radius;
}

.table-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
}

/**
  modal header section
  this must be wrapped in a pTemplate="header"
*/
.dynamic-dialog {
  padding: 0px 1rem;

  .dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;

    h4 {
      font-weight: 600;
      margin-right: 1rem;
      padding-right: 1rem;
      color: #007dc3;
      border-right: 1px solid #7c7c7c;
    }

    h5 {
      color: #9c9c9c;
      font-weight: 600;
    }
  }

  .dialog-content {
    padding: 0rem 0rem 1rem !important;
  }

  .dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.warning {
  color: #f29423 !important;
}

.error {
  color: #dd1919 !important;
}

.success {
  color: #09a74b !important;
}

.info {
  color: #007dc3 !important;
}

// datatable-scrollable
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #d6d6d6 !important;
}

.p-datatable .p-datatable-header {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid transparent;
  border-width: 0 0 0px 0 !important;
  padding: 0rem !important;
  font-weight: normal !important;
}

.p-datatable-scrollable-wrapper {
  position: relative;
  border: 0 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

// datatable-scrollable
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #d6d6d6 !important;
}

.p-datatable .p-datatable-header {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid transparent;
  border-width: 0 0 0px 0 !important;
  padding: 0rem !important;
  font-weight: normal !important;
}

.p-datatable-scrollable-wrapper {
  position: relative;
  border: 0 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgb(167, 69, 69);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.nav-link {
  padding: 0.6rem 1rem !important;
  color: #495057;
  font-size: 16px;
  font-weight: 500;
}

.nav-link:hover {
  color: $main-primary !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: $main-primary;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

// form Header
.tausiFormTitleHeader {
  display: flex;
  align-items: center;
  color: #007dc3;
  font-weight: bold;
}

.tausiFormTitleHeader::after {
  content: "";
  flex: 1;
  margin-left: 1rem;
  height: 1px;
  background-color: #efefef;
}

// agent and council setup
.option-container {
  width: 60%;
  margin: 0 auto;

  .option-button {
    cursor: pointer;
    pointer-events: auto;
    opacity: unset;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-radius: $border-radius;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
    transition: background-color 0.3s ease-in-out 0s;
    text-decoration: none;
    margin: 24px 0px;

    .content {
      width: 100%;
      display: flex;
      margin: 24px;
      color: rgb(59, 66, 68);
      flex-direction: row;

      .icon {
        height: auto;
        margin-right: 24px;
        align-self: center;

        mat-icon {
          font-size: 3rem;
          color: #696969;
        }
      }

      .title {
        margin-left: 2rem;

        h4 {
          color: $main-primary;
        }
      }

      p {
        color: #696969;
      }
    }

    .arrow-button {
      flex: 1 1 100px;
      transition: background-color 0.3s ease-in-out 0s;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      line-height: 0;
      padding-right: 1rem;
      padding-left: 0.5rem;

      .arrow > svg {
        width: 2em;
        height: 2em;
        color: #909090;
      }
    }
  }
}

.container-fluid {
  width: 90% !important;
  margin: 0 auto;
}

.container-fluid-sm {
  width: 80% !important;
  margin: 0 auto;
}

.pi-1x {
  font-size: 1.5rem;
}

.pi-2x {
  font-size: 2rem;
}

.tabs-container {
  margin-bottom: 3rem;

  .nav-tabs {
    width: 100%;
    padding: 0rem 0px 0rem;
    margin-bottom: 1.5em;
    border-bottom: 3px solid transparent !important;

    .nav-link {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: -1px;
      background: 0 0;
      border: none;
      border-bottom: 3px solid transparent;
      border-radius: 0rem;
    }

    .nav-link.active {
      font-weight: 600s;
      color: #007dc3;
      border-radius: 0px;
      background-color: transparent;
      border-bottom-width: 3px;
      border-bottom-color: #007dc3;
    }
  }
}

.summary {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  width: 100%;
  background-color: rgb(243, 251, 255);
  padding: 1rem 1.2rem;
  border-radius: 6px;
}

.p-tag .p-tag-icon {
  margin-right: 0rem;
  margin-left: 0.35rem;
  font-size: 0.75rem;
}

.p-tag.p-tag-success {
  background-color: #22c03c;
}

.p-tag.p-tag-warning {
  background-color: #fbc02d;
}

.details-container {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;

  h4 {
    padding: 0;
    margin: 0;
    color: #007dc3;
    font-size: 1.4rem !important;
    font-weight: 600 !important;

    span {
      color: #333333 !important;
    }
  }

  h5 {
    padding: 0;
    margin: 0;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
  }
}

.inner-page-header {
  h3 {
    font-weight: 600 !important;
  }
}

.p-p-3 {
  padding: 0;
}

.title {
  font-weight: 600;
  color: $main-primary;
}

.progress-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.profile_image {
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.7rem 0.75rem 0.7rem 3.5rem !important;
  font-size: 1.2 rem !important;
  color: #fff !important;
}

.bg-c-warning {
  background: #fff8ef !important;
  color: #ff8a00 !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  font-weight: 600;
}

.custom-marker {
  width: 30px;
  height: 30px;
  //border: 2px solid white;
  //border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  //box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  i{
    font-size: 2.5em !important;
  }
}

