@import "~@angular/material/theming";

$light-primary: #c8e6ff;
$main-primary: #007dc3;
$dark-primary: #004c8a;
$light-inner: #e7f2fb;

$border-radius: 0.2rem;
$font-size: 1.1rem;

$bg-overlay: rgba(0, 0, 0, 0.52);

$color-success: #01cc6d;
$color-warning: #ffe600;
$color-danger: #ff0000;
$color-info: #0f51bd;

$color-default: #909090;
$color-active: #979797;
$tabs-track: #cfcfcf;
$table-strip: #eef7ff;
