@font-face {
  font-family: "Eina";
  src: local("Eina 03 Bold"),
    url("./assets/fonts/eina/eina-03-bold.woff2") format("woff2"),
    url("./assets/fonts/eina/eina-03-bold.woff") format("woff"),
    url("./assets/fonts/eina/eina-03-bold.ttf") format("ttf"),
    url("./assets/fonts/eina/eina-03-bold.eot?#iefix") format("eot");
  font-weight: 700;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Eina";
  src: local("Eina 03 SemiBold"),
    url("./assets/fonts/eina/eina-03-semibold.woff2") format("woff2"),
    url("./assets/fonts/eina/eina-03-semibold.woff") format("woff"),
    url("./assets/fonts/eina/eina-03-semibold.ttf") format("ttf"),
    url("./assets/fonts/eina/eina-03-semibold.eot?#iefix") format("eot");
  font-weight: 600;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Eina";
  src: local("Eina 03 Regular"),
    url("./assets/fonts/eina/eina-03-regular.woff2") format("woff2"),
    url("./assets/fonts/eina/eina-03-regular.woff") format("woff"),
    url("./assets/fonts/eina/eina-03-regular.ttf") format("ttf"),
    url("./assets/fonts/eina/eina-03-regular.eot?#iefix") format("eot");
  font-weight: 400;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "GoogleSans";
  src: local("GoogleSans-Regular"),
    url("./assets/fonts/GoogleSans/GoogleSans-Regular.ttf") format("ttf");
  font-weight: 500;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "GoogleSans";
  src: local("GoogleSans-Medium"),
    url("./assets/fonts/GoogleSans/GoogleSans-Medium.ttf") format("ttf");
  font-weight: 600;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "GoogleSans";
  src: local("GoogleSans-Bold"),
    url("./assets/fonts/GoogleSans/GoogleSans-Bold.ttf") format("ttf");
  font-weight: 700;
  unicode-range: U+000-5FF;
}


@font-face {
  font-family: "American";
  src: local("GT America, Regular"),
    url("./assets/fonts/america/gt-america-standard-regular.woff") format("woff"),
    url("./assets/fonts/america/gt-america-standard-regular.ttf") format("ttf"),
    url("./assets/fonts/america/gt-america-standard-regular.eot?#iefix") format("eot");
  font-weight: 500;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "American";
  src: local("GT America, Bold"),
    url("./assets/fonts/america/gt-america-bold.woff") format("woff");
  font-weight: 700;
  unicode-range: U+000-5FF;
}

  @font-face {
    font-family: "American";
    src: local("GT America, Medium"),
      url("./assets/fonts/america/gt-america-standard-medium.woff") format("woff"),
      url("./assets/fonts/america/gt-america-standard-medium.ttf") format("ttf"),
      url("./assets/fonts/america/gt-america-standard-medium.eot?#iefix") format("eot");
    font-weight: 600;
    unicode-range: U+000-5FF;
}